import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/reusables/Card';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ToggleSwitch from '@beewise/toggle';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import TechButton from 'components/reusables/TechButton';
import { isEqual } from 'lodash-es';
import { fetchAutoFeaturesState } from '../../actions';
import { AUTO_SCAN_TYPES, getDaysOptions, getDaysValue } from './utils';
import { fetchSaveConfig } from '../../../Config/actions/config.actions';

const ActivityManagerCenter = ({ bhome }) => {
    const [feedStatus, setFeedStatus] = useState(null);
    const [scanStatus, setScanStatus] = useState(null);
    const [autoScanType, setAutoScanType] = useState(null);
    const dispatch = useDispatch();
    const autoFeaturesState = useSelector(state => state.actionsMonitoring.autoFeaturesState, shallowEqual);

    useEffect(() => {
        dispatch(fetchAutoFeaturesState({ id: bhome?.id }));
    }, [bhome?.id, dispatch]);

    useEffect(() => {
        setFeedStatus(structuredClone(bhome?.config?.activity_manager?.auto_feed_policy));
        setScanStatus(structuredClone(bhome?.config?.activity_manager?.auto_scan_policy));
        setAutoScanType(bhome?.config?.activity_manager?.visual_inspection_type);
    }, [bhome?.config?.activity_manager]);

    const onPropChange =
        (cb, type, convertToNumber = false) =>
        value => {
            cb(status => ({
                ...status,
                [type]: convertToNumber ? Number(value) : value,
            }));
        };

    const onSelectChange = cb => value => {
        cb(status => ({
            ...status,
            days_of_week: Object.keys(status.days_of_week).reduce((acc, key) => {
                acc[key] = !!value.includes(key);
                return acc;
            }, status.days_of_week),
        }));
    };

    const isDisabled =
        isEqual(feedStatus, bhome?.config?.activity_manager?.auto_feed_policy) &&
        isEqual(scanStatus, bhome?.config?.activity_manager?.auto_scan_policy) &&
        isEqual(autoScanType, bhome?.config?.activity_manager?.visual_inspection_type);

    const handleSaveConfig = () => {
        dispatch(
            fetchSaveConfig(bhome.id, {
                config: {
                    ...bhome.config,
                    activity_manager: {
                        ...bhome.config.activity_manager,
                        visual_inspection_type: autoScanType,
                        auto_feed_policy: feedStatus,
                        auto_scan_policy: scanStatus,
                    },
                },
            })
        );
    };

    return (
        <div className="activity-manager-center">
            <Card className="activity-manager" title="Auto Features" defaultOpen={false} expandable>
                <div className="activity-manager-sides">
                    <div className="activity-manager-side">
                        <h3>Feeding</h3>
                        <div className="activity-manager-line">
                            <div className="activity-manager-item-status">
                                Status:
                                <ToggleSwitch
                                    id="feed-status"
                                    onChange={onPropChange(setFeedStatus, 'auto_feed_enabled')}
                                    value={feedStatus?.auto_feed_enabled}
                                />
                            </div>
                            {autoFeaturesState?.lastFeedMessage?.sent_at ? (
                                <div>
                                    <span className="bold">Last activity:</span>{' '}
                                    {new Date(autoFeaturesState?.lastFeedMessage?.sent_at).toLocaleString()},{' '}
                                    <span className="bold">initiated by:</span>{' '}
                                    {autoFeaturesState?.lastScanMessage?.email ?? 'system'},{' '}
                                    <span className="bold">status:</span> {autoFeaturesState?.lastScanMessage?.status}
                                </div>
                            ) : (
                                <div>No activity yet</div>
                            )}
                        </div>
                        <div className="activity-manager-line">
                            <div>Cycle</div>
                            <TextField
                                label="Cycle Days"
                                value={feedStatus?.auto_feed_cycle_days}
                                type="number"
                                onChange={onPropChange(setFeedStatus, 'auto_feed_cycle_days', true)}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Enabled Days</div>
                            <SelectField
                                isMulti
                                options={getDaysOptions(feedStatus)}
                                value={getDaysValue(feedStatus)}
                                onChange={onSelectChange(setFeedStatus)}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Min. temperature</div>
                            <TextField
                                label="Min. temperature"
                                value={feedStatus?.min_temperature}
                                type="number"
                                onChange={onPropChange(setFeedStatus, 'min_temperature', true)}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Max. temperature</div>
                            <TextField
                                label="Max. temperature"
                                value={feedStatus?.max_temperature}
                                type="number"
                                onChange={onPropChange(setFeedStatus, 'max_temperature', true)}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Start Time</div>
                            <TextField
                                label="Start Time"
                                value={feedStatus?.auto_feed_start_time}
                                onChange={onPropChange(setFeedStatus, 'auto_feed_start_time')}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>End Time</div>
                            <TextField
                                label="End Time"
                                value={feedStatus?.auto_feed_end_time}
                                onChange={onPropChange(setFeedStatus, 'auto_feed_end_time')}
                            />
                        </div>
                    </div>
                    <div className="activity-manager-side">
                        <h3>Scanning</h3>
                        <div className="activity-manager-line">
                            <div className="activity-manager-item-status">
                                Status:
                                <ToggleSwitch
                                    id="scan-status"
                                    onChange={onPropChange(setScanStatus, 'auto_scan_enabled')}
                                    value={scanStatus?.auto_scan_enabled}
                                />
                            </div>
                            {autoFeaturesState?.lastScanMessage?.sent_at ? (
                                <div>
                                    <span className="bold">Last activity:</span>{' '}
                                    {new Date(autoFeaturesState?.lastScanMessage?.sent_at).toLocaleString()},{' '}
                                    <span className="bold">initiated by:</span>{' '}
                                    {autoFeaturesState?.lastScanMessage?.email ?? 'system'},{' '}
                                    <span className="bold">status:</span> {autoFeaturesState?.lastScanMessage?.status}
                                </div>
                            ) : (
                                <div>No activity yet</div>
                            )}
                        </div>
                        <div className="activity-manager-line">
                            <div>Mode</div>
                            <SelectField
                                options={AUTO_SCAN_TYPES}
                                value={autoScanType}
                                type="number"
                                onChange={setAutoScanType}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Cycle</div>
                            <TextField
                                label="Cycle Days"
                                value={scanStatus?.auto_scan_cycle_days}
                                type="number"
                                onChange={onPropChange(setScanStatus, 'auto_scan_cycle_days', true)}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Enabled Days</div>
                            <SelectField
                                isMulti
                                options={getDaysOptions(scanStatus)}
                                value={getDaysValue(scanStatus)}
                                onChange={onSelectChange(setScanStatus)}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Min. temperature</div>
                            <TextField
                                label="Min. temperature"
                                value={scanStatus?.min_temperature}
                                type="number"
                                onChange={onPropChange(setScanStatus, 'min_temperature', true)}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Max. temperature</div>
                            <TextField
                                label="Max. temperature"
                                value={scanStatus?.max_temperature}
                                type="number"
                                onChange={onPropChange(setScanStatus, 'max_temperature', true)}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>Start Time</div>
                            <TextField
                                label="Start Time"
                                value={scanStatus?.auto_scan_start_time}
                                onChange={onPropChange(setScanStatus, 'auto_scan_start_time')}
                            />
                        </div>
                        <div className="activity-manager-line">
                            <div>End Time</div>
                            <TextField
                                label="End Time"
                                value={scanStatus?.auto_scan_end_time}
                                onChange={onPropChange(setScanStatus, 'auto_scan_end_time')}
                            />
                        </div>
                    </div>
                </div>
                <div className="activity-manager-buttons">
                    <TechButton
                        onClick={handleSaveConfig}
                        className="activity-manager-save"
                        enabledInAnyState
                        disabled={isDisabled}
                    >
                        Save
                    </TechButton>
                </div>
            </Card>
        </div>
    );
};

ActivityManagerCenter.propTypes = {
    bhome: PropTypes.shape(),
};

export default ActivityManagerCenter;
