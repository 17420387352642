import { createAsyncAction } from '@beewise/utils';

export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const ON_UPDATE_BHOME_STATUS = 'ON_UPDATE_BHOME_STATUS';
export const ON_UPDATE_BHOME_MAP = 'ON_UPDATE_BHOME_MAP';
export const ON_RECEIVE_LOG_ERROR = 'ON_RECEIVE_LOG_ERROR';
export const ON_RECEIVE_NEW_MESSAGE = 'ON_RECEIVE_NEW_MESSAGE';
export const ON_UPDATE_MESSAGE_STATUS = 'ON_UPDATE_MESSAGE_STATUS';
export const ON_RECEIVE_NEW_LOGS = 'ON_RECEIVE_NEW_LOGS';
export const ON_RECEIVE_NEW_RANGE_LOGS = 'ON_RECEIVE_NEW_RANGE_LOGS';
export const ON_RECEIVE_BEEHOME_FILE_TO_DOWNLOAD = 'ON_RECEIVE_BEEHOME_FILE_TO_DOWNLOAD';
export const ON_RECEIVE_DEVICES = 'ON_RECEIVE_DEVICES';
export const ON_UPDATE_CONFIG = 'ON_UPDATE_CONFIG';
export const ON_UPDATE_CLOUD_CONFIG = 'ON_UPDATE_CLOUD_CONFIG';
export const ON_RECEIVE_CAMERA_DEVICES = 'ON_RECEIVE_CAMERA_DEVICES';
export const ON_RECEIVE_NEW_ROBOT_STATUS = 'ON_RECEIVE_NEW_ROBOT_STATUS';
export const ON_GET_IR_TEMPERATURE = 'ON_GET_IR_TEMPERATURE';
export const ON_RECEIVE_NEW_ROBOT_VERSIONS = 'ON_RECEIVE_NEW_ROBOT_VERSIONS';
export const ON_RECEIVE_NEW_ROBOT_READ_RFID = 'ON_RECEIVE_NEW_ROBOT_READ_RFID';
export const ON_UPDATE_JENKINS_TEST_RESULTS = 'ON_UPDATE_JENKINS_TEST_RESULTS';
export const ON_GET_SYSTEM_STATUSES = 'ON_GET_SYSTEM_STATUSES';
export const ON_CREATE_SSH_TUNNEL = 'ON_CREATE_SSH_TUNNEL';
export const ON_FAIL_UPDATE_BHOME_STATUS = 'ON_FAIL_UPDATE_BHOME_STATUS';
export const ON_UPDATE_CONNECTED_USERS_LIST = 'ON_UPDATE_CONNECTED_USERS_LIST';
export const ON_FRAME_SCALE_CALIBRATE_INFO = 'ON_FRAME_SCALE_CALIBRATE_INFO';
export const ON_FRAME_SCALE_WEIGHT = 'ON_FRAME_SCALE_WEIGHT';
export const ON_REPORT_HOURLY_SOLAR_DATA = 'ON_REPORT_HOURLY_SOLAR_DATA';
export const ON_REPORT_SOLAR_DATA = 'ON_REPORT_SOLAR_DATA';
export const ON_UPDATE_GPS_LOCATION = 'ON_UPDATE_GPS_LOCATION';
export const ON_REPORT_POWER_DATA = 'ON_REPORT_POWER_DATA';
export const ON_GET_POWER_DATA = 'ON_GET_POWER_DATA';
export const ON_UPDATE_INTERLOCK_STATUS = 'ON_UPDATE_INTERLOCK_STATUS';
export const ON_UPDATE_SYRUP_LEVEL = 'ON_UPDATE_SYRUP_LEVEL';
export const ON_UPDATE_HONEY_LEVEL = 'ON_UPDATE_HONEY_LEVEL';
export const ON_GET_TEST_STATUS = 'ON_GET_TEST_STATUS';
export const ON_GET_AVAILABLE_TESTS = 'ON_GET_AVAILABLE_TESTS';
export const ON_GET_SYS_INFO = 'ON_GET_SYS_INFO';
export const ON_SET_LAYOUT = 'ON_SET_LAYOUT';
export const FETCH_ALL_BHOMES = createAsyncAction('FETCH_ALL_BHOMES');
export const FETCH_CURRENT_BHOME = createAsyncAction('FETCH_CURRENT_BHOME');
export const FETCH_TECHNICIAN_ACTIVITIES = createAsyncAction('FETCH_TECHNICIAN_ACTIVITIES');
export const FETCH_CHANGE_BHOME_STATUS = createAsyncAction('FETCH_CHANGE_BHOME_STATUS');
export const FETCH_STOP_BHOME = createAsyncAction('FETCH_STOP_BHOME');
export const SET_CURRENT_BHOME = 'SET_CURRENT_BHOME';
export const SET_LIVE_VIDEO_URL = 'SET_LIVE_VIDEO_URL';
export const FETCH_ALL_VIEWS = createAsyncAction('FETCH_ALL_VIEWS');
export const FETCH_DOWNLOAD_FAT_IMG = createAsyncAction('FETCH_DOWNLOAD_FAT_IMG');
export const FETCH_CREATE_VIEW = createAsyncAction('FETCH_CREATE_VIEW');
export const FETCH_DELETE_VIEW = createAsyncAction('FETCH_DELETE_VIEW');
